window['cmsg']['assets'] = window['cmsg']['assets'] || {};
window['cmsg']['assets']["en"] = Object.assign(window['cmsg']['assets']["en"] || {}, {
    "5706": {
        "js": ["f636fb6b6f75a3e8.strings.js"],
        "css": []
    },
    "8701": {
        "js": ["a789995c551e4f25.strings.js"],
        "css": []
    }
});